$(document).ready(function() {
  new WOW().init();

  var bLazy = new Blazy({
      selector: 'img,.b-lazy-background'
  });
                
  $('#nav-button').on('click', function() {
      $('.navigation').toggleClass('active');
      $('.header').toggleClass('nav-fixed');
      $('.wrapper').toggleClass('nav-fixed');
      if($('.nav-down').length) {
        $('#masthead').removeClass('nav-down').addClass('nav-up');       
      }

  });
  $('.services_button').on('click', function(e) {
      e.preventDefault();
      $('.features_list').toggleClass('is-visible');
  });
  $(document).scroll(function() {
    var scroll = $(this).scrollTop();
    if (scroll >= 1) {
      $(".header").addClass("menu-fixed");
    } else{
      $(".header").removeClass("menu-fixed");
    }
  });
  
  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('#masthead').outerHeight();

  $(window).scroll(function(event){
      didScroll = true;
  });

  setInterval(function() {
      if (didScroll) {
          hasScrolled();
          didScroll = false;
      }
  }, 250);

  function hasScrolled() {
      var st = $(this).scrollTop();
 
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('#masthead').removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('#masthead').removeClass('nav-up').addClass('nav-down');
                }
            }

            lastScrollTop = st;
  }
});
if ($(".fancybox").length){
    $(document).ready(function() {
        $(".fancybox").fancybox({
            openEffect  : 'none',
            closeEffect : 'none'
        });
    });
}
